export function setupReplyModals() {
  document.addEventListener('click', (event) => {
    if (event.target.matches('[data-reply-modal-toggle]')) {
      openReplyModal(event);
    }
  });
}

function openReplyModal(event) {
  document.querySelector(`.modal[data-repliable-uuid="${event.target.dataset.replyModalToggle}"]`).showModal();
}
